import {useEffect, useState} from "react";
import '../styles/MatchOddTab.css';
import loadingIcon from "../assets/loading_ball_icon1.png";
import refreshIcon from "../assets/refresh_icon.svg";
import {Divider, Space, Spin} from "antd";
import MatchOddItem from "./match_odd_item";

function  OddPagedItem(props) {
    const [oddList, setOddList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [multiple, setMultiple] = useState(props.multiple);
    const [shouldLoadNext, setShouldLoadNext] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [noResult, setNoResult] = useState('Odds not ready!')
    const [loadError, setLoadError] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);

    const loadOdds = (page, count, next_page) => {
            if(next_page) {
                setNextLoading(true);
            }
            else {
                setLoading(true);
            }
            let url = `https://api.i7soccer.com/varitex/api/server/football/odds?page=${page}&offset=${props.offset}`;
            fetch(url).then(response => response.json())
                .then((data) => {
                    let oddData = data.data
                    if(!next_page) {
                        if(typeof oddData.odds !== 'undefined' && oddData.odds.length > 0) {
                            setOddList(oddData.odds);

                            if(multiple || height >= 1600) {
                                setShouldLoadNext(true);
                            }
                            else {
                                props.callback(oddData.odds, props.page);
                            }
                        }
                        else {
                            setNoResult('Odds not available!')
                            setLoadError(true);
                        }
                        setLoading(false);
                    }
                    else {
                        if(typeof oddData.odds !== 'undefined' && oddData.odds.length > 0) {
                            //update the existing odd list
                            const updatedOddList = [...oddList, ...oddData.odds]
                            setOddList(updatedOddList);
                            //cache the response
                            props.callback(updatedOddList, props.page);
                        }
                        setNextLoading(false)
                    }
                })
                .catch((error) => {
                    if(next_page) {
                        setNextLoading(false);
                    }
                    else {
                        if(count === 1) {
                            loadOdds(props.page, 2, false);
                        }
                        else {
                            setNoResult('Network issue!');
                            setLoadError(true);
                            setLoading(false);
                        }
                    }
                });
    };
    const handleReload = () => {
        //loadMatchOdds(props.page, 0);
        setLoadError(false);
    }
    const fixtureFormattedDate = (date) => {
        const required = new Date(date);
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        const dateFormatter = new Intl.DateTimeFormat('en-US', options);

        const formattedDate = dateFormatter.format(required);

        return formattedDate;
    }
    const handleOddMore = (match_odd) => {
        localStorage.setItem("matchOdd", JSON.stringify(match_odd));
        //navigates to odd detail screen
        window.open(`/odds/detail/${match_odd.fixture.id}`, '_blank');
    }
    const is1xBetExist = (bookmakers) => {
        let isExist = false;
        bookmakers.forEach((maker) => {
            if(maker.name === '1xBet') {
                isExist = true;
            }
        });

        return isExist;
    }
    const isBoth1xBetAndBet365Exist = (bookmakers) => {
        let doesExist = false;
        bookmakers.forEach((maker) => {
            if(maker.name === '1xBet' || maker.name === 'Bet365') {
                doesExist = true;
            }
        });

        return doesExist;
    }

    const oddDisplay = () => {
        return (
            <div className='Odds-box'>
                <div className="Odds-loading" hidden={!loading}>
                    <img src={loadingIcon} className='circle-progress' height='42px' width='42px'/>
                </div>
                <div className="Odds-error" hidden={!loadError}>
                    <div className='Error-box'>
                        <span>{noResult}</span>
                        <button type="button" className="btn btn-primary" onClick={handleReload}>
                            <img src={refreshIcon} alt=''/> Reload!
                        </button>
                    </div>
                </div>

                <div className='Odd-container' hidden={loading || loadError}>
                    <div>
                        {
                            Array.isArray(oddList) && oddList.length > 0 &&
                            oddList.map((match_odd, index) =>
                                <div>
                                    {
                                        (index === 0 || oddList[(index - 1)].league.id !== match_odd.league.id) ?
                                            <div className='League-header' style={{marginTop: (index > 0) ? '10px' : '0px'}}>
                                                <Space direction='horizontal' size='small'>
                                                    <img src={match_odd.league.logo} width='16px' height='16px'/>
                                                    <span>{match_odd.league.country} - {match_odd.league.name}</span>
                                                </Space>
                                                <div className='Header-bet'>
                                                    <span className='Header-bet-value'>1</span>
                                                    <span className='Header-bet-value'>X</span>
                                                    <span className='Header-bet-value'>2</span>
                                                </div>
                                            </div> : null
                                    }
                                    <div className='Match-odd-box1'>
                                        <div className='Odd-match-time'>
                                            <span>{fixtureFormattedDate(match_odd.fixture.date)}</span>
                                        </div>
                                        <div>
                                            <Space direction='vertical' size='small'>
                                                <img src={match_odd.teams.home.logo} width='16px' height='16px'/>
                                                <img src={match_odd.teams.away.logo} width='16px' height='16px'/>
                                            </Space>
                                        </div>
                                        <div>
                                            <Space direction='vertical' size='small'>
                                                <span>{match_odd.teams.home.name}</span>
                                                <span>{match_odd.teams.away.name}</span>
                                            </Space>
                                        </div>
                                        <div>
                                            {
                                                match_odd.bookmakers.map((bookmaker, _index) => (
                                                    (bookmaker.name === '1xBet') ?
                                                        <div className='Bookmaker-item'>
                                                            <MatchOddItem bookmarker={bookmaker} />
                                                        </div> :
                                                        (!is1xBetExist(match_odd.bookmakers) && bookmaker.name === 'Bet365') ?
                                                            <div className='Bookmaker-item'>
                                                                <MatchOddItem bookmarker={bookmaker} />
                                                            </div> :
                                                            ((!isBoth1xBetAndBet365Exist(match_odd.bookmakers)) && _index === 1) ?
                                                                <div className='Bookmaker-item'>
                                                                    <MatchOddItem bookmarker={bookmaker} />
                                                                </div> : null
                                                ))
                                            }
                                            <div className='View-more'>
                                            <span style={{cursor: 'pointer'}} onClick={() => handleOddMore(match_odd)}>
                                                View bets & odds detail
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (index !== (oddList.length - 1) && (index + 1 <= (oddList.length - 1)) &&
                                            oddList[(index + 1)].league.id === match_odd.league.id) ?
                                            <Divider type='horizontal' className='odds-divider-padding' /> : null
                                    }
                                    {
                                        (index === (oddList.length - 1) && nextLoading) ?
                                            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
                                                <Spin size='small' />
                                            </div> : null
                                    }

                                    {
                                        ((index === 2 || index%8 === 0) && index !== 0) &&
                                        <div className='mobile-ads'>
                                            <script async
                                                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                                                    crossOrigin="anonymous"></script>
                                            <ins className="adsbygoogle"
                                                 style={{display: 'block'}}
                                                 data-ad-client="ca-pub-9133642311191277"
                                                 data-ad-slot="9413504326"
                                                 data-ad-format="auto"
                                                 data-full-width-responsive="true"></ins>
                                            <script>
                                                (adsbygoogle = window.adsbygoogle || []).push({});
                                            </script>
                                        </div>
                                    }
                                </div>)
                        }
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        if(typeof props.odds !== 'undefined' &&
            Array.isArray(props.odds) && props.odds.length > 0) {
            //fill odds from the previous load
            setOddList(props.odds);
        }
        else {
            loadOdds(props.page, 1, false);
        }
    }, [props.page, multiple]);

    useEffect(() => {
        if(shouldLoadNext) {
            loadOdds(props.page + 1, 1, true);
            setShouldLoadNext(false);
        }
    }, [shouldLoadNext]);

    //gets change i browser height
    useEffect(() => {
        const handleResize = () => {
            if(window.innerHeight >= 1600) {
                setMultiple(true);
            }
            else {
                setMultiple(false);
            }
            setHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (<div>
        {
            oddDisplay()
        }
    </div>);
}

export default OddPagedItem;