import { useEffect, useState } from 'react';
import '../../styles/MatchDetail.css';
import {Progress, Space, Tabs} from 'antd';
import loadingIcon from '../../assets/loading_ball_icon1.png';
import {useParams} from 'react-router-dom';
import TabPane from "antd/lib/tabs/TabPane";
const RedCard = require('../../assets/ic_red_card.png');
const YellowCard = require('../../assets/ic_yellow_card.png');
const SoccerBall = require('../../assets/ic_soccer_ball.png');
const Shirt = require('../../assets/shirt.png');

function MatchDetail() {
    const { match } = useParams();
    const matchInfo = JSON.parse(localStorage.getItem('matchData'));
    const [loading, setLoading] = useState(true);
    const [enableH2h, setEnableH2h] = useState(false);
    const [enableStats, setEnableStats] = useState(false);
    const [enableLineup, setEnableLineup] = useState(false);
    const [enableTable, setEnableTable] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [errorLoadingTable, setErrorLoadingTable] = useState(false);
    const [groupTable, setGroupTable] = useState(false);
    const [standingShowing, setStandingShowing] = useState('ALL');
    const [tableError, setTableError] = useState("League table failed!");
    const [message, setMessage] = useState("Error fetching News Item!");
    const [loadError, setLoadError] = useState(false);
    const [matchData, setMatchData] = useState({});
    const [topScorer, setTopScorer] = useState([]);
    const [standing, setStanding] = useState([]);
    const [homeForm, setHomeForm] = useState([]);
    const [awayForm, setAwayForm] = useState([]);
    const [matchEvents, setMatchEvents] = useState([]);
    const [homeNext, setHomeNext] = useState([]);
    const [awayNext, setAwayNext] = useState([]);
    const [prediction, setPrediction] = useState({});
    const [lineups, setLineups] = useState([]);
    const [stats, setStats] = useState([]);
    const [h2h, setH2h] = useState([]);
    const key = matchInfo.api_key;
    const apikey = key.substring(4, key.length - 5);
    const league = matchInfo.league;
    const homeId = matchInfo.home_id;
    const awayId = matchInfo.away_id;
    const matchDate = matchInfo.match_date;

    const loadMatch = () => {
        let url = `https://apiv3.apifootball.com?action=get_events&APIkey=${apikey}&match_id=${match}`;
        fetch(url).then(response => response.json())
            .then((data) => {
                //checks if there is api error response
                if (typeof data.message !== 'undefined') {
                    setLoadError(true);
                }
                else {
                    prepareMatchEvent(data);
                    setMatchData(data[0]);

                    //sets lineups
                    if(typeof  data[0].lineup !== "undefined") {
                        setLineups(data[0].lineup);
                        setEnableLineup(true);
                    }
                    else {
                        setEnableLineup(false);
                        console.log('++++++++++++++++++++ Match Lineups undefined.');
                    }

                    //sets stats
                    if(typeof data[0].statistics !== "undefined") {
                        let statsData = data[0].statistics;
                        console.log(`Stats: ${JSON.stringify(statsData)}`);
                        if(Array.isArray(statsData) && statsData.length > 0) {
                            setStats(statsData);
                            setEnableStats(true);
                        }
                        else {
                            console.log('++++++++++++++++++++ Match Stats not array or empty.');
                        }
                    }
                    else {
                        console.log('++++++++++++++++++++ Match Stats undefined.');
                    }
                }

                setLoading(false);
            })
            .catch((error) => {
                setLoadError(true);
                setLoading(false);

                console.log(`Matches failed: ${error}`);
            });
    };
    const fetchH2h = (home, away) => {
        let url = `https://apiv3.apifootball.com?action=get_H2H&firstTeamId=${home}&secondTeamId=${away}&APIkey=${apikey}`;
        fetch(url).then(h2h => h2h.json())
            .then((data) => {
                //checks if there is api error response
                if (typeof data.message !== 'undefined') {
                    console.log(`Undefined H2h: ${data.message}`);
                }
                else {
                    if(data.firstTeam_lastResults.length > 5) {
                        setHomeForm(data.firstTeam_lastResults.slice(0, 5));
                    }
                    else {
                        setHomeForm(data.firstTeam_lastResults);
                    }
                    if(data.secondTeam_lastResults.length > 5) {
                        setAwayForm(data.secondTeam_lastResults.slice(0, 5));
                    }
                    else {
                        setAwayForm(data.secondTeam_lastResults);
                    }
                    setH2h(data.firstTeam_VS_secondTeam);
                    setEnableH2h(true);
                }

                //console.log(`H2h data: ${data}`);
            })
            .catch((error) => {
                console.log(`Error loading H2h: ${error}`);
            });
    };
    const fetchLeagueTable = () => {
        let url = `https://apiv3.apifootball.com?action=get_standings&APIkey=${apikey}&league_id=${league}`;
        fetch(url).then(table => table.json())
            .then((data) => {
                //checks if there is api error response
                if (typeof data.message !== 'undefined') {
                    console.log(`Undefined League table: ${data.message}`);
                    setErrorLoadingTable(true);
                    setTableError('League table not available.');
                }
                else {
                    if(Array.isArray(data) && data.length > 4) {
                        //const sortedData = data.sort((a, b) => parseInt(b.home_league_PTS) - parseInt(a.home_league_PTS));
                        //checks if its a grouped list
                        let groupCounter = 0;
                        for(const item of data) {
                            if(item.overall_league_position === '1') {
                                groupCounter += 1;
                            }
                        }
                        if(groupCounter > 3) {
                            setGroupTable(true);
                        }
                        setStanding(data);
                        setEnableTable(true);
                    }
                    else {
                        setErrorLoadingTable(true);
                        setTableError('Table not available.');
                    }
                }
                setLoadingTable(false);
            })
            .catch((error) => {
                setErrorLoadingTable(true);
                setLoadingTable(false);
                setTableError('League table failed.');
                console.log(`Error loading table: ${error}`);
            });
    };
    const getTopScorer = () => {
        let url = `https://apiv3.apifootball.com?action=get_topscorers&APIkey=${apikey}&league_id=${league}`;
        fetch(url).then(top_scorer => top_scorer.json())
            .then((data) => {
                //checks if there is api error response
                if (typeof data.message !== 'undefined') {
                    console.log(`Undefined Top Scorer: ${data.message}`);
                }
                else {
                    if(data.length > 5){
                        setTopScorer(data.slice(0, 5));
                    }
                    else {
                        setTopScorer(data);
                    }
                }
            })
            .catch((error) => {
                console.log(`Error loading top scorer: ${error}`);
            });
    };
    const fetchMatchPrediction = () => {
        let url = `https://apiv3.apifootball.com/?action=get_predictions&match_id=${match}&APIkey=${apikey}`;
        fetch(url).then(response => response.json())
            .then((data) => {
                //checks if there is api error response
                if (typeof data.message !== 'undefined') {
                    console.log(`Undefined Match prediction: ${data.message}`);
                }
                else {
                    setPrediction(data[0]);
                }
            })
            .catch((error) => {
                console.log(`Match prediction failed: ${error}`)
            });
    };
    const nextMatches = (teamId, date, end_date, type) => {
        let url = `https://apiv3.apifootball.com?action=get_events&from=${getStartDate(date)}&to=${end_date}&APIkey=${apikey}&team_id=${teamId}`;
        fetch(url).then(next_matches => next_matches.json())
            .then((data) => {
                //checks if there is api error response
                if (typeof data.message !== 'undefined') {
                    console.log(`Undefined Next matches for team with ID:${teamId},\n${data.message}`)
                }
                else {
                    if(type=== 'home') {
                        if(data.length > 5) {
                            setHomeNext(data.slice(0, 5));
                        }
                        else {
                            setHomeNext(data);
                        }
                    }
                    else {
                        if(data.length > 5) {
                            setAwayNext(data.slice(0, 5));
                        }
                        else {
                            setAwayNext(data);
                        }
                    }
                }
                //console.log(`Next Matches: ${data}`);
            })
            .catch((error) => {
                console.log(`Error loading next matches: ${error}`);
            });
    };
    const prepareMatchEvent = (matchData) => {
        let goal_events = matchData[0].goalscorer;
        let substitution_events = matchData[0].substitutions;
        let card_events = matchData[0].cards;
        //format events by time
        let formatted_goals_event = formatEvent(goal_events, 'goal');
        let formatted_card_events = formatEvent(card_events, 'card');
        let formatted_sub_home_events = formatEvent(substitution_events.home, 'sub_home');
        let formatted_sub_away_events = formatEvent(substitution_events.away, 'sub_away');
        //combine the formatted events
        let general_events = formatted_goals_event.concat(
            formatted_card_events,
            formatted_sub_home_events,
            formatted_sub_away_events
        )
        //sort the result
        const sorted_events = general_events.sort((a, b) => a.formatted_time - b.formatted_time);

        //console.log(`############################ Combined event: ${JSON.stringify(general_events)}`);

        setMatchEvents(sorted_events);
    };
    const matchTime = (date) => {
        const userOffset = new Date().getTimezoneOffset()*60*1000;
        const localDate = new Date(date);
        const utcDate = new Date(localDate.getTime() + userOffset);

        const formatter = utcDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        return formatter;
    }


    useEffect(() => {
        nextMatches(homeId, matchDate, getEndDate(), 'home')
        nextMatches(awayId, matchDate, getEndDate(), 'away')
        //delay for some seconds
        setTimeout(() => {
            fetchH2h(homeId, awayId);
            fetchLeagueTable();
        }, 400);
    }, [])

    useEffect(() => {
        setLoading(true);
        //delay and load matches
        fetchMatchPrediction();
        getTopScorer();
        setTimeout(() => loadMatch(), 600);
    }, []);

    return (
        <div className="Match-detail">
            <div className="Match-detail-container">
                <div className='Match-detail-box'>
                    <div className='MatchHeader' hidden={loading || loadError}>
                        <Space direction='vertical' size='small' className='Team-logo-label'>
                            <img src={matchData.team_home_badge} className='Team-logo' />
                            <span className='Team'>{matchData.match_hometeam_name}</span>
                        </Space>

                        <Space direction='vertical' size='small' className='Status-score'>
                            {
                                (matchData.match_status !== "") ? <span>
                                    {matchData.match_hometeam_score} - {matchData.match_awayteam_score}
                                </span> : null
                            }
                            {
                                matchData.match_status === "" ? <di>
                                        <Space direction='vertical' style={{alignItems: 'center'}}>
                                            <span className='Team'>{matchData.match_date}</span>
                                            <span className='Team'>{
                                                matchTime(`${matchData.match_date} ${matchData.match_time}`)
                                            }</span>
                                        </Space>
                                    </di> :
                                    <span className='Team'>{
                                        matchStatus(matchData.match_time, matchData.match_status)
                                    }</span>
                            }
                        </Space>

                        <Space direction='vertical' size='small' className='Team-logo-label'>
                            <img src={matchData.team_away_badge} className='Team-logo' />
                            <span className='Team'>{matchData.match_awayteam_name}</span>
                        </Space>
                    </div>

                    <div className='Match-loaded' hidden={loading || loadError}>
                        <Tabs defaultActiveKey='1'>
                            <TabPane tab="Preview" key='1'>
                                <div>
                                    {
                                        Array.isArray(stats) && stats.length > 1 &&
                                        <div className='Team-next-container'
                                             hidden={(matchData.match_status === "" || stats.length === 0)}>
                                            <div className='Team-form' style={{padding: '8px'}}>
                                                {
                                                    Array.isArray(stats) && stats.length > 0 &&
                                                    (<div style={{marginTop: '4px'}}>
                                                        {
                                                            stats.map((stat, index) =>
                                                                validSideA(stat.type) &&
                                                                <div className='Stat-container' style={{marginTop: '2px'}}>
                                                                    <div className='Stat-left'>
                                                                        <div className='Stat-item' style={{width: `${statValue(stat.home, stat.away)}%`,
                                                                            backgroundColor: `${progressColor(
                                                                                statValue(stat.home, stat.away),
                                                                                statValue(stat.away, stat.home)
                                                                            )}`}}>
                                                                        </div>
                                                                    </div>
                                                                    <div>{stat.type}</div>
                                                                    <div className='Stat-right'>
                                                                        <div className='Stat-item' style={{width: `${statValue(stat.away, stat.home, stat.type)}%`,
                                                                            backgroundColor: `${progressColor(
                                                                                statValue(stat.away, stat.home),
                                                                                statValue(stat.home, stat.away)
                                                                            )}`}}>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                        }
                                                    </div>)
                                                }
                                            </div>

                                            <div className='Team-form' style={{padding: '8px'}}>
                                                {
                                                    Array.isArray(stats) && stats.length > 0 &&
                                                    (<div style={{marginTop: '4px'}}>
                                                        {
                                                            stats.map((stat, index) =>
                                                                validSideB(stat.type) &&
                                                                <div className='Stat-container' style={{marginTop: '2px'}}>
                                                                    <div className='Stat-left'>
                                                                        <div className='Stat-item' style={{width: `${statValue(stat.home, stat.away, stat.type)}%`,
                                                                            backgroundColor: `${progressColor(
                                                                                statValue(stat.home, stat.away),
                                                                                statValue(stat.away, stat.home)
                                                                            )}`}}>
                                                                        </div>
                                                                    </div>
                                                                    <div>{stat.type}</div>
                                                                    <div className='Stat-right'>
                                                                        <div className='Stat-item' style={{width: `${statValue(stat.away, stat.home, stat.type)}%`,
                                                                            backgroundColor: `${progressColor(
                                                                                statValue(stat.away, stat.home),
                                                                                statValue(stat.home, stat.away)
                                                                            )}`}}>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                        }
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className='Prediction-container'
                                         hidden={(matchData.match_status !== "")}>
                                        <div className='Prediction-box'>
                                            <div className='Prediction-header'>
                                                Match Winner
                                            </div>
                                            <div className='Prediction-progress-spacer'>
                                                <div className='row' style={{display: 'flex', height: '80px', alignContent: 'space-evenly'}}>
                                                    <div className='Label-box'>
                                                        <span className='Prediction-label'>HOME</span>
                                                    </div>
                                                    <div className='Label-box'>
                                                        <span className='Prediction-label'>DRAW</span>
                                                    </div>
                                                    <div className='Label-box'>
                                                        <span className='Prediction-label'>AWAY</span>
                                                    </div>
                                                </div>
                                                <div className='row' style={{display: 'flex', height: '80px', alignContent: 'space-evenly'}}>
                                                    <div className='Label-box'>
                                                        <Progress strokeLinecap='butt' percent={parseInt(prediction.prob_HW ?? '0.0')}
                                                                  strokeWidth='12px' strokeColor={predictionColor(parseInt(prediction.prob_HW ?? '0'))}/>
                                                    </div>
                                                    <div className='Label-box'>
                                                        <Progress strokeLinecap='butt' percent={parseInt(prediction.prob_D ?? '0.0')}
                                                                  strokeWidth='12px' strokeColor={predictionColor(parseInt(prediction.prob_D ?? '0'))}/>
                                                    </div>
                                                    <div className='Label-box'>
                                                        <Progress strokeLinecap='butt' percent={parseInt(prediction.prob_AW ?? '0.0')}
                                                                  strokeWidth='12px' strokeColor={predictionColor(parseInt(prediction.prob_AW ?? '0'))}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Prediction-box'>
                                            <div className='Prediction-header'>
                                                Double Chance
                                            </div>
                                            <div className='Prediction-progress-spacer-2'>
                                                <div className='row' style={{display: 'flex', height: '80px', alignContent: 'space-evenly'}}>
                                                    <div className='Label-box'>
                                                        <span className='Prediction-label'>H/DRAW</span>
                                                    </div>
                                                    <div className='Label-box'>
                                                        <span className='Prediction-label'>H/AWAY</span>
                                                    </div>
                                                    <div className='Label-box'>
                                                        <span className='Prediction-label'>A/DRAW</span>
                                                    </div>
                                                </div>
                                                <div className='row' style={{display: 'flex', height: '80px', alignContent: 'space-evenly'}}>
                                                    <div className='Label-box'>
                                                        <Progress strokeLinecap='butt' percent={parseInt(prediction.prob_HW_D ?? '0.0')}
                                                                  strokeWidth='12px' strokeColor={predictionColor(parseInt(prediction.prob_HW_D ?? '0.0'))}/>
                                                    </div>
                                                    <div className='Label-box'>
                                                        <Progress strokeLinecap='butt' percent={parseInt(prediction.prob_HW_AW ?? '0.0')}
                                                                  strokeWidth='12px' strokeColor={predictionColor(parseInt(prediction.prob_HW_AW ?? '0'))}/>
                                                    </div>
                                                    <div className='Label-box'>
                                                        <Progress strokeLinecap='butt' percent={parseInt(prediction.prob_AW_D ?? '0.0')}
                                                                  strokeWidth='12px' strokeColor={predictionColor(prediction.prob_AW_D ?? '0')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mobile-ads' style={{width: '100%'}}>
                                        <script async
                                                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                                                crossOrigin="anonymous"></script>
                                        <ins className="adsbygoogle"
                                             style={{display: 'block'}}
                                             data-ad-client="ca-pub-9133642311191277"
                                             data-ad-slot="9413504326"
                                             data-ad-format="auto"
                                             data-full-width-responsive="true"></ins>
                                        <script>
                                            (adsbygoogle = window.adsbygoogle || []).push({});
                                        </script>
                                    </div>

                                    <div>{
                                        Array.isArray(matchEvents) && matchEvents.length > 0 && (
                                            <div className='Match-event'>
                                                <div className='Match-event-child'>
                                                    <div className='Event-home'></div>
                                                    <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                                        Time
                                                    </div>
                                                    <div className='Event-away'></div>
                                                </div>
                                                {
                                                    matchEvents.map((match_event) => (
                                                        <div className='Match-event-child'>
                                                            <div className='Event-home'>{
                                                                (match_event.type === 'card' &&
                                                                    match_event.home_fault !== "") ? (
                                                                        <Space direction='horizontal'>
                                                                            <span>{match_event.home_fault}</span>
                                                                            <img className='Event-image' src={cardImage(match_event.card)} alt=''/>
                                                                        </Space>) :
                                                                    (match_event.type === 'goal' &&
                                                                        match_event.home_scorer !== "") ? (
                                                                            <Space direction='horizontal' className='Goal-space'>
                                                                                <Space direction='vertical' size='0px'>
                                                                                    <div>
                                                                                        <span>{match_event.home_scorer}</span>
                                                                                        <span style={{fontWeight: 'bold'}}>&nbsp;({match_event.score})</span>
                                                                                    </div>
                                                                                    {
                                                                                        (match_event.home_assist !== "") ?
                                                                                            <span>{match_event.home_assist}</span> : null
                                                                                    }
                                                                                </Space>
                                                                                <img className='Event-image' src={SoccerBall} alt=''/>
                                                                            </Space>
                                                                        ) :
                                                                        (match_event.type === 'sub_home') ? (
                                                                            <Space direction='horizontal'>
                                                                                <Space direction='vertical' size='0px'>
                                                                                    <span>{match_event.substitution.split("|")[0]}</span>
                                                                                    <span>{match_event.substitution.split("|")[1]}</span>
                                                                                </Space>
                                                                                <img className='Event-image' src={Shirt} alt=''/>
                                                                            </Space>
                                                                        ) : null
                                                            }</div>
                                                            <div className='Event-time'>
                                                                {match_event.time}
                                                            </div>
                                                            <div className='Event-away'>{
                                                                (match_event.type === 'card' &&
                                                                    match_event.away_fault !== "") ? (
                                                                        <Space direction='horizontal'>
                                                                            <img className='Event-image' src={cardImage(match_event.card)} alt=''/>
                                                                            <span>{match_event.away_fault}</span>
                                                                        </Space>) :
                                                                    (match_event.type === 'goal' &&
                                                                        match_event.away_scorer !== "") ? (
                                                                            <Space direction='horizontal' className='Goal-space'>
                                                                                <img className='Event-image' src={SoccerBall} alt=''/>
                                                                                <Space direction='vertical' size='0px'>
                                                                                    <div>
                                                                                        <span>{match_event.away_scorer}</span>
                                                                                        <span style={{fontWeight: 'bold'}}>&nbsp;({match_event.score})</span>
                                                                                    </div>
                                                                                    {
                                                                                        (match_event.away_assist !== "") ?
                                                                                            <span>{match_event.away_assist}</span> : null
                                                                                    }
                                                                                </Space>
                                                                            </Space>
                                                                        ) :
                                                                        (match_event.type === 'sub_away') ? (
                                                                            <Space direction='horizontal'>
                                                                                <img className='Event-image' src={Shirt} alt=''/>
                                                                                <Space direction='vertical' size='0'>
                                                                                    <span>{match_event.substitution.split("|")[0]}</span>
                                                                                    <span>{match_event.substitution.split("|")[1]}</span>
                                                                                </Space>
                                                                            </Space>
                                                                        ) : null
                                                            }</div>
                                                        </div>
                                                    ))
                                                }</div>
                                        )
                                    }</div>

                                    {
                                        Array.isArray(awayForm) && awayForm.length > 0 &&
                                        <div className='Team-next-container'>
                                            <div className='Team-form'>
                                                <div className='Team-form-header-a'>
                                                    {matchData.match_hometeam_name} last matches
                                                </div>
                                                {
                                                    Array.isArray(homeForm) && homeForm.length > 0 &&
                                                    (<div style={{marginTop: '4px'}}>
                                                        {
                                                            homeForm.map((match, index) => (
                                                                <div className='Form-spacer'
                                                                     style={{backgroundColor: (index%2 === 0) ? 'beige' : '#FF149345'}}>
                                                                    <div>
                                                    <span className='Table-text large-screen-device'>
                                                        {reverseDate(match.match_date).replaceAll('/','.')}
                                                    </span>
                                                                    </div>
                                                                    <div className='Table-text'>
                                                                        {formatLeagueName(match.league_name)}
                                                                    </div>
                                                                    <div className='Form-spacer-inside'>
                                                        <span className='Table-text' style={{textAlign: 'end'}}>
                                                            {match.match_hometeam_name}
                                                        </span>

                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                                            <span className='Table-text large-screen-device' style={{textAlign: 'center'}}>
                                                                {match.match_hometeam_score}-{match.match_awayteam_score}
                                                            </span>

                                                                            <span className='Table-text small-screen-device' style={{textAlign: 'center'}}>
                                                                {match.match_hometeam_score} - {match.match_awayteam_score}
                                                            </span>

                                                                            <span className='Table-text2 small-screen-device'>
                                                                    {reverseDate(match.match_date).replaceAll('/','.')}
                                                            </span>
                                                                        </div>

                                                                        <span className='Table-text' style={{textAlign: 'start'}}>
                                                            {match.match_awayteam_name}
                                                        </span>
                                                                    </div>
                                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                                        <div className='Status-box'
                                                                             style={{display: 'flex', justifyContent: 'center',
                                                                                 alignItems: 'center',
                                                                                 backgroundColor:
                                                                                     getMatchResult(
                                                                                         matchData.match_hometeam_id,
                                                                                         match.match_hometeam_id,
                                                                                         match.match_hometeam_score,
                                                                                         match.match_awayteam_score
                                                                                     )
                                                                                     === 'W' ? 'green' :
                                                                                         getMatchResult(
                                                                                             matchData.match_hometeam_id,
                                                                                             match.match_hometeam_id,
                                                                                             match.match_hometeam_score,
                                                                                             match.match_awayteam_score
                                                                                         ) === 'L' ? 'red' : 'gray',
                                                                                 color: 'white', fontWeight: 'bold', fontSize: '11px',
                                                                                 textAlign: 'center', fontFamily: 'Tahoma, Arial'
                                                                             }}>
                                                                            {
                                                                                getMatchResult(
                                                                                    matchData.match_hometeam_id,
                                                                                    match.match_hometeam_id,
                                                                                    match.match_hometeam_score,
                                                                                    match.match_awayteam_score
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>)
                                                }
                                            </div>

                                            <div className='Team-form'>
                                                <div className='Team-form-header-b'>
                                                    {matchData.match_awayteam_name} last matches
                                                </div>
                                                {
                                                    Array.isArray(awayForm) && awayForm.length > 0 &&
                                                    (<div style={{marginTop: '4px'}}>
                                                        {
                                                            awayForm.map((match, index) => (
                                                                <div className='Form-spacer'
                                                                     style={{backgroundColor: (index%2 === 0) ? 'beige' : '#FF149345'}}>
                                                                    <div>
                                                    <span className='Table-text large-screen-device'>
                                                        {reverseDate(match.match_date).replaceAll('/','.')}
                                                    </span>
                                                                    </div>
                                                                    <div className='Table-text'>
                                                                        {formatLeagueName(match.league_name)}
                                                                    </div>
                                                                    <div className='Form-spacer-inside'>
                                                        <span className='Table-text' style={{textAlign: 'end'}}>
                                                            {match.match_hometeam_name}
                                                        </span>

                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                                            <span className='Table-text large-screen-device' style={{textAlign: 'center'}}>
                                                                {match.match_hometeam_score}-{match.match_awayteam_score}
                                                            </span>

                                                                            <span className='Table-text small-screen-device' style={{textAlign: 'center'}}>
                                                                {match.match_hometeam_score} - {match.match_awayteam_score}
                                                            </span>

                                                                            <span className='Table-text2 small-screen-device'>
                                                                    {reverseDate(match.match_date).replaceAll('/','.')}
                                                            </span>
                                                                        </div>

                                                                        <span className='Table-text' style={{textAlign: 'start'}}>
                                                            {match.match_awayteam_name}
                                                        </span>
                                                                    </div>
                                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                                        <div className='Status-box'
                                                                             style={{display: 'flex', justifyContent: 'center',
                                                                                 alignItems: 'center',
                                                                                 backgroundColor:
                                                                                     getMatchResult(
                                                                                         matchData.match_hometeam_id,
                                                                                         match.match_hometeam_id,
                                                                                         match.match_hometeam_score,
                                                                                         match.match_awayteam_score
                                                                                     )
                                                                                     === 'W' ? 'green' :
                                                                                         getMatchResult(
                                                                                             matchData.match_hometeam_id,
                                                                                             match.match_hometeam_id,
                                                                                             match.match_hometeam_score,
                                                                                             match.match_awayteam_score
                                                                                         ) === 'L' ? 'red' : 'gray',
                                                                                 color: 'white', fontWeight: 'bold', fontSize: '11px',
                                                                                 textAlign: 'center', fontFamily: 'Tahoma, Arial'
                                                                             }}>
                                                                            {
                                                                                getMatchResult(
                                                                                    matchData.match_hometeam_id,
                                                                                    match.match_hometeam_id,
                                                                                    match.match_hometeam_score,
                                                                                    match.match_awayteam_score
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        Array.isArray(topScorer) && topScorer.length > 0 &&
                                        <div className='Top-goal-box' hidden={topScorer.length === 0}>
                                            <div className='Top-goal' style={{backgroundColor: 'lightgrey',
                                                borderTopLeftRadius: '8px', borderTopRightRadius: '8px',
                                                height: '30px', alignItems: 'center'}}>
                                                <div></div>
                                                <div style={{fontSize: '12px', fontWeight: 'bold'}}>
                                                    {(typeof matchData.league_name !== 'undefined' &&
                                                        (matchData.league_name.includes("Cup") ||
                                                            (matchData.league_name.includes("Cup")) ||
                                                            (matchData.league_name.includes("Intl")))
                                                    ) ? 'Cup' : 'League'}  Top Scorers
                                                </div>
                                                <div>Goals</div>
                                            </div>
                                            {
                                                Array.isArray(topScorer) && topScorer.length > 0 &&
                                                (<div>
                                                    {
                                                        topScorer.map((player, index) => (
                                                            <div className='Top-goal'
                                                                 style={{backgroundColor: (index%2 !== 0) ? '#77889926' : 'transparent',
                                                                     paddingTop: '4px', paddingBottom: '4px'}}>
                                                                <div style={{textAlign: 'center', fontSize: '12px', fontWeight: 'bold'}}>
                                                                    {(index + 1)}
                                                                </div>
                                                                <div>
                                                <span style={{fontSize: '11px', fontWeight: 'bold'}}>
                                                    {player.player_name}
                                                    <span style={{fontSize: '11px', fontStyle: 'italic', fontWeight: 'normal'}}>
                                                        &nbsp;&nbsp;({player.team_name})
                                                    </span>
                                                </span>
                                                                </div>
                                                                <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                                                                    {player.goals}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>)
                                            }
                                        </div>
                                    }


                                    <div className='mobile-ads' style={{width: '100%'}}>
                                        <script async
                                                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                                                crossOrigin="anonymous"></script>
                                        <ins className="adsbygoogle"
                                             style={{display: 'block'}}
                                             data-ad-client="ca-pub-9133642311191277"
                                             data-ad-slot="9413504326"
                                             data-ad-format="auto"
                                             data-full-width-responsive="true"></ins>
                                        <script>
                                            (adsbygoogle = window.adsbygoogle || []).push({});
                                        </script>
                                    </div>


                                    <div className='Team-next-container'>
                                        {
                                            Array.isArray(homeNext) && homeNext.length > 0 &&
                                            <div className='Team-form'>
                                                <div className='Team-next-match'>
                                                    {matchData.match_hometeam_name} next matches
                                                </div>
                                                {
                                                    Array.isArray(homeNext) && homeNext.length > 0 &&
                                                    (<div style={{marginTop: '4px'}}>
                                                        {
                                                            homeNext.map((match, index) => (
                                                                <div className='Form-spacer'
                                                                     style={{backgroundColor: (index%2 === 0) ? 'beige' : '#FF149345'}}>
                                                                    <div>
                                                        <span className='Table-text large-screen-device'>
                                                            {reverseDate(match.match_date).replaceAll('/','.')}
                                                        </span>
                                                                    </div>
                                                                    <div className='Table-text'>
                                                                        {formatLeagueName(match.league_name)}
                                                                    </div>
                                                                    <div className='Form-spacer-inside'>
                                                        <span className='Table-text' style={{textAlign: 'end'}}>
                                                            {match.match_hometeam_name}
                                                        </span>

                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <span className='Table-text2 small-screen-device'>
                                                                    {reverseDate(match.match_date).replaceAll('/','.')}
                                                            </span>
                                                                            <span className='Table-text3'>
                                                                {matchTime(`${matchData.match_date} ${matchData.match_time}`)}
                                                            </span>
                                                                        </div>

                                                                        <span className='Table-text' style={{textAlign: 'start'}}>
                                                            {match.match_awayteam_name}
                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>)
                                                }
                                            </div>
                                        }

                                        {
                                            Array.isArray(awayNext) && awayNext.length > 0 &&
                                            <div className='Team-form'>
                                                <div className='Team-next-match-b'>
                                                    {matchData.match_awayteam_name} next matches
                                                </div>
                                                {
                                                    Array.isArray(awayNext) && awayNext.length > 0 &&
                                                    (<div style={{marginTop: '4px'}}>
                                                        {
                                                            awayNext.map((match, index) => (
                                                                <div className='Form-spacer'
                                                                     style={{backgroundColor: (index%2 === 0) ? 'beige' : '#FF149345'}}>
                                                                    <div>
                                                        <span className='Table-text large-screen-device'>
                                                            {reverseDate(match.match_date).replaceAll('/','.')}
                                                        </span>
                                                                    </div>
                                                                    <div className='Table-text'>
                                                                        {formatLeagueName(match.league_name)}
                                                                    </div>
                                                                    <div className='Form-spacer-inside'>
                                                        <span className='Table-text' style={{textAlign: 'end'}}>
                                                            {match.match_hometeam_name}
                                                        </span>

                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <span className='Table-text2 small-screen-device'>
                                                                    {reverseDate(match.match_date).replaceAll('/','.')}
                                                            </span>
                                                                            <span className='Table-text3'>
                                                                {matchTime(`${matchData.match_date} ${matchData.match_time}`)}
                                                            </span>
                                                                        </div>

                                                                        <span className='Table-text' style={{textAlign: 'start'}}>
                                                            {match.match_awayteam_name}
                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>)
                                                }
                                            </div>
                                        }

                                    </div>

                                    <div style={{width: '100%', height: '100px'}}></div>
                                </div>
                            </TabPane>
                            <TabPane tab="Standing" key='2'>
                                <div>

                                    {
                                        Array.isArray(standing) && standing.length > 0 &&
                                        <div className='mobile-ads-2' style={{width: '100%'}}>
                                            <script async
                                                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                                                    crossOrigin="anonymous"></script>
                                            <ins className="adsbygoogle"
                                                 style={{display: 'block'}}
                                                 data-ad-client="ca-pub-9133642311191277"
                                                 data-ad-slot="9413504326"
                                                 data-ad-format="auto"
                                                 data-full-width-responsive="true"></ins>
                                            <script>
                                                (adsbygoogle = window.adsbygoogle || []).push({});
                                            </script>
                                        </div>
                                    }

                                    <div>
                                        {
                                            Array.isArray(standing) && standing.length > 0 &&
                                            <div className='Standing-box' hidden={standing.length === 0}>

                                                <div className='Standing-element Standing-header'>
                                                    <div className='Standing-text'>#</div>
                                                    <div>Team</div>
                                                    <div className='Standing-text'>P</div>
                                                    <div className='Standing-text large-screen-device'>W</div>
                                                    <div className='Standing-text large-screen-device'>D</div>
                                                    <div className='Standing-text large-screen-device'>L</div>
                                                    <div className='Standing-text'>GD</div>
                                                    <div className='Standing-text'>PTS</div>
                                                </div>
                                                <>
                                                    {
                                                        standing.map((table_item, index) => (
                                                        <div style={{backgroundColor: (index%2 !== 0) ? '#77889926' : 'transparent',
                                                            paddingTop: (groupTable && index !== 0 && isNewGroup(table_item, standingShowing)) ? '0px' : '4px' ,
                                                            paddingBottom: '4px', width: '100%'}}>
                                                            {
                                                                standingShowing === 'ALL' ?
                                                                    <div>
                                                                        <>{
                                                                            table_item.overall_league_position === '1' && index !== 0 &&
                                                                            <div hidden={!groupTable} style={{height: '4px', width: '100%', backgroundColor: 'cadetblue', marginBottom: '2px'}}>
                                                                            </div>
                                                                        }</>
                                                                        <div className='Standing-element'>
                                                                        <div className='Standing-text'
                                                                             style={{border: homeId === table_item.team_id ? '3px solid green' :
                                                                                     awayId === table_item.team_id ? '3px solid darkorange' : '0px solid blue',
                                                                                 borderRadius: '8px'
                                                                             }}>
                                                                            {table_item.overall_league_position}
                                                                        </div>
                                                                        <div>{table_item.team_name}</div>
                                                                        <div className='Standing-text'>{table_item.overall_league_payed}</div>
                                                                        <div className='Standing-text large-screen-device'>{table_item.overall_league_W}</div>
                                                                        <div className='Standing-text large-screen-device'>{table_item.overall_league_D}</div>
                                                                        <div className='Standing-text large-screen-device'>{table_item.overall_league_L}</div>
                                                                        <div className='Standing-text'>
                                                                            {parseInt(table_item.overall_league_GF) - parseInt(table_item.overall_league_GA)}
                                                                        </div>
                                                                        <div className='Standing-text'>{table_item.overall_league_PTS}</div>
                                                                    </div>
                                                                    </div> :
                                                                    standingShowing === 'HOME' ?
                                                                        <div>
                                                                            <>{
                                                                                table_item.home_league_position === '1' && index !== 0 &&
                                                                                <div hidden={!groupTable} style={{height: '4px', width: '100%', backgroundColor: 'cadetblue', marginBottom: '2px'}}>
                                                                                </div>
                                                                            }</>
                                                                            <div className='Standing-element'>
                                                                            <div className='Standing-text'
                                                                                 style={{border: homeId === table_item.team_id ? '3px solid green' :
                                                                                         awayId === table_item.team_id ? '3px solid darkorange' : '0px solid blue',
                                                                                     borderRadius: '8px'
                                                                                 }}>
                                                                                {table_item.home_league_position}
                                                                            </div>
                                                                            <div className='Standing-text'>{table_item.team_name}</div>
                                                                            <div className='Standing-text'>{table_item.home_league_payed}</div>
                                                                            <div className='Standing-text large-screen-device'>{table_item.home_league_W}</div>
                                                                            <div className='Standing-text large-screen-device'>{table_item.home_league_D}</div>
                                                                            <div className='Standing-text large-screen-device'>{table_item.home_league_L}</div>
                                                                            <div className='Standing-text'>
                                                                                {parseInt(table_item.home_league_GF) - parseInt(table_item.home_league_GA)}
                                                                            </div>
                                                                            <div className='Standing-text'>{table_item.home_league_PTS}</div>
                                                                        </div>
                                                                        </div> :
                                                                        <div>
                                                                            <>{
                                                                                table_item.away_league_position === '1' && index !== 0 &&
                                                                                <div hidden={!groupTable} style={{height: '4px', width: '100%', backgroundColor: 'cadetblue', marginBottom: '2px'}}>
                                                                                </div>
                                                                            }</>
                                                                            <div className='Standing-element'>
                                                                            <div className='Standing-text'
                                                                                 style={{border: homeId === table_item.team_id ? '3px solid green' :
                                                                                         awayId === table_item.team_id ? '3px solid darkorange' : '0px solid blue',
                                                                                     borderRadius: '8px'
                                                                                 }}>
                                                                                {table_item.away_league_position}
                                                                            </div>
                                                                            <div className='Standing-text'>{table_item.team_name}</div>
                                                                            <div className='Standing-text'>{table_item.away_league_payed}</div>
                                                                            <div className='Standing-text large-screen-device'>{table_item.away_league_W}</div>
                                                                            <div className='Standing-text large-screen-device'>{table_item.away_league_D}</div>
                                                                            <div className='Standing-text large-screen-device'>{table_item.away_league_L}</div>
                                                                            <div className='Standing-text'>
                                                                                {parseInt(table_item.overall_league_GF) - parseInt(table_item.overall_league_GA)}
                                                                            </div>
                                                                            <div className='Standing-text'>{table_item.away_league_PTS}</div>
                                                                        </div>
                                                                        </div>
                                                            }
                                                        </div>
                                                    ))
                                                    }
                                                </>
                                            </div>
                                        }
                                    </div>

                                    <div hidden={!loadingTable} className='loader'>
                                        <img src={loadingIcon} className='circle-progress' width='42px' height='42px'/>
                                    </div>

                                    <div style={{ height: '70vh', display: 'flex', justifyContent: 'center',
                                        alignItems: 'center' }} hidden={!errorLoadingTable && !loadingTable}>
                                        <div className="alert alert-error" role="alert">
                                            <span>{tableError}</span>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>

                    <div hidden={!loading} className='loader'>
                        <img src={loadingIcon} className='circle-progress' width='42px' height='42px'/>
                    </div>
                    <div style={{ height: '80vh', display: 'flex', justifyContent: 'center',
                        alignItems: 'center' }} hidden={!loadError && !loading}>
                        <div className="alert alert-error" role="alert">
                            <span>{message}</span>
                        </div>
                    </div>
                </div>
                <div className="Ads-detail-box">
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                            crossOrigin="anonymous"></script>

                    <ins className="adsbygoogle"
                         style={{display: 'block'}}
                         data-ad-client="ca-pub-9133642311191277"
                         data-ad-slot="1789422257"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </div>
            </div>
        </div>
    );
}

const isNewGroup = (tableData, type) => {
    if(tableData.overall_league_position === '1' && type === 'ALL'){
        return true;
    }

    if(tableData.away_league_position === '1' && type === 'AWAY') {
        return true;
    }

    if(tableData.home_league_position === '1' && type === 'HOME') {
        return true;
    }

    return false;
}
const formatEvent = (events, type) => {
    if(Array.isArray(events) && events.length > 0) {
      return events.map((event) => {
            if(event.time.includes("+")) {
                let time_extra = event.time.split('+');
                let time_string = `${time_extra[0]}.${time_extra[1]}`;
                let formattedTime = parseFloat(time_string);

                return {...event, formatted_time: formattedTime, type: type}
            }
            else {
                let formattedTime = parseFloat(event.time);
                return {...event, formatted_time: formattedTime, type: type}
            }
        });
    }
    else return [];
};

const getEndDate = () => {
    const required_date = new Date();
    required_date.setDate(required_date.getDate() + 20);

    // Format the new date to "yyyy-mm-dd"
    const year = required_date.getFullYear();
    const month = String(required_date.getMonth() + 1).padStart(2, '0');
    const day = String(required_date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

const getStartDate = (date) => {
    let required_date;
    let current_date = new Date();
    let match_date = new Date(date);

    if(match_date <= current_date) {
        required_date = current_date;
    }
    else {
        required_date = match_date;
    }

    required_date.setDate(required_date.getDate() + 1);

    // Format the new date to "yyyy-mm-dd"
    const year = required_date.getFullYear();
    const month = String(required_date.getMonth() + 1).padStart(2, '0');
    const day = String(required_date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

const formatLeagueName = (league) => {
    let league_name;
    if(league.includes("-")) {
        league_name = league.split('-')[0];
    }
    else {
        league_name = league;
    }

    if(league_name.split(" ").length > 1) {
        let rst = league_name.split(" ").map((lg) => lg[0]);
        return rst.join("");
    }
    else {
        return league_name;
    }

};

const reverseDate = (date) => {
    let required_date = new Date(date);
    return required_date.toLocaleDateString(
        'en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
}

const getMatchResult = (main, child_home, home_score, away_score) => {
    if(main === child_home) {
        if(parseInt(home_score ?? '0') > parseInt(away_score ?? '0')) {
            return 'W';
        }
        else if(parseInt(home_score ?? '0') === parseInt(away_score ?? '0')) {
            return 'D';
        }
        else {
            return 'L';
        }
    }
    else {
        if(parseInt(home_score ?? '0') < parseInt(away_score ?? '0')) {
            return 'W';
        }
        else if(parseInt(home_score ?? '0') === parseInt(away_score ?? '0')) {
            return 'D';
        }
        else {
            return 'L';
        }
    }
}

const matchStatus = (match_time, match_status) => {
    if (match_status === "Finished" ||
        match_status === "After ET" || match_status === "After Pen.") {
        return 'Finished';
    }
    else if (match_status === "Half Time") {
        return 'Half Time';
    }
    else if (match_status === "Postponed") {
        return 'Postponed';
    }
    else if (match_status === "Cancelled") {
        return 'Cancelled';
    }
    else if (match_status === "Susp.") {
        return 'Match Suspended';
    }
    else if (match_status === "Awarded") {
        return 'Walk Over';
    }
    else if (typeof match_status !== 'undefined' && match_status != null
        && match_status !== "") {
        return `${match_status}'`
    }
    else {
        return match_time;
    }
}

const validSideA = (type) => {
    if(type === "Attacks" || type === "Ball Possession" || type === "Passes Accurate" ||
        type === "Passes Total" || type === "Saves" || type === "Dangerous Attacks") {
        return true;
    }
    else {
        return false
    }
}

const validSideB = (type) => {
    if(type === "Shots Total" || type === "On Target" || type === "Off Target" ||
        type === "Fouls" || type === "Corners" || type === 'Throw In') {
        return true;
    }
    else {
        return false
    }
}

const statValue = (value, valueB) => {
    let a = value.replace('%','');
    let b = valueB.replace('%', '');
    let value1 = parseInt(a ?? '0');
    let value2 = parseInt(b ?? '0');
    let total = value1 + value2;
    //let result = (value1/total) * 100;
    let result = (value1 * 100) / total;
    return `${result}`;
}

const predictionColor = (value) => {
    if(value > 70) {
        return '#43A047FF';
    }
    else if(value > 48) {
        return '#063fd7';
    }
    else {
        return '#FF5722FF';
    }
}

const progressColor = (value, value2) => {
    if(value > value2) {
        return '#43A047FF';
    }
    else if(value > value2) {
        return '#063fd7';
    }
    else {
        return '#FF5722FF';
    }
}

const cardImage = (card_type) => {
    if(card_type === 'yellow card') {
        return YellowCard
    }
    else {
        return RedCard
    }
}

export default MatchDetail;