import {useEffect, useRef, useState} from "react";
import '../../styles/MatchOddTab.css';
import loadingIcon from '../../assets/loading_ball_icon1.png';
import {Pagination} from "@mui/material";
import refreshIcon from "../../assets/refresh_icon.svg";
import OddPagedItem from "../../components/odd_paged_item";

function  MatchOddsTab(props) {
    const [pageSize, setPageSize] = useState(1);
    const [current, setCurrent] = useState(1);
    const [loadPage, setLoadPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [multiplePage, setMultiplePage] = useState(false);
    const [noResult, setNoResult] = useState('Odds not ready!');
    const [loadError, setLoadError] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);
    const [received, setReceived] = useState(false);
    const [receivedPage, setReceivedPage] = useState(0);
    const [receivedOdd, setReceivedOdd] = useState([]);
    const [oddCollection, setOddCollection] = useState(new Map());

    const loadMatchOdds = (page, count) => {
        setLoading(true);
        let url = `https://api.i7soccer.com/varitex/api/server/football/odds?page=${page}&offset=${props.offset}`;
        fetch(url).then(response => response.json())
            .then((data) => {
                let oddData = data.data
                if(typeof oddData.odds !== 'undefined') {
                    if(height >= 1600) {
                        let total;
                        if(oddData.total%2 !== 0) {
                            total = Math.floor(oddData.total/2) + 1;
                        }
                        else {
                            total = oddData.total/2;
                        }

                        setPageSize(total);
                    }
                    else {
                        setPageSize(oddData.total);
                    }
                }
                else {
                    setNoResult('Odds not available!')
                    setLoadError(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                if(count === 1) {
                    loadMatchOdds(page, 2);
                }
                else {
                    setNoResult('Network issue!');

                    setLoadError(true);
                    setLoading(false);
                }
            });
    }
    const handlePageChange = (event, page) => {
        let required_page = page;
        if(height >= 1600) {
            required_page = (page * 2) - 1;
            setMultiplePage(true);
        }
        else {
            setMultiplePage(false);
        }
        setLoadPage(required_page);
        setCurrent(page);
    }

    const handleCallbacks = (data, page) => {
        setReceivedOdd(data);
        setReceivedPage(page);
        setTimeout(() => setReceived(true), 3000);
    }

    const oddPageItemDisplay = () => {
        return <OddPagedItem
                    page={loadPage}
                    multiple={multiplePage}
                    offset={props.offset}
                    callback={handleCallbacks}
                    odds={oddCollection.has(loadPage) ? oddCollection.get(loadPage) : []} />;
    }

    useEffect(() => loadMatchOdds(loadPage, 1), []);

    useEffect(() => {
        if(received) {
            setReceived(false);
            const updateCollection = oddCollection;
            updateCollection.set(receivedPage, receivedOdd);
            setOddCollection(updateCollection);

            console.log(`############## odds collections: ${oddCollection.size}`);
        }
    }, [received]);
    //gets change i browser height
    useEffect(() => {
        const handleResize = () => {
            if(window.innerHeight >= 1600) {
                setMultiplePage(true);
            }
            else {
                setMultiplePage(false);
            }
            setHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='Odds-box'>
            <div className="Odds-loading" hidden={!loading}>
                <img src={loadingIcon} className='circle-progress' height='42px' width='42px'/>
            </div>
            <div className="Odds-error" hidden={!loadError}>
                <div className='Error-box'>
                    <span>{noResult}</span>
                    <button type="button" className="btn btn-primary" onClick={() => loadMatchOdds(loadPage, 1)}>
                        <img src={refreshIcon} alt=''/> Reload!
                    </button>
                </div>
            </div>

            <div className='Odd-container' hidden={loading || loadError}>
                <div>{
                    oddPageItemDisplay()
                }</div>
                <div className='Pagination-box'>
                    <Pagination
                        page={current}
                        count={pageSize}
                        onChange={handlePageChange}
                        shape='rounded'
                        color='standard'
                    />
                </div>
            </div>
        </div>
    );
}

export  default MatchOddsTab;