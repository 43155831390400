
import '../../src/styles/MatchTips.css'
import {Tabs} from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import PredictionTab from "../tabs/tips/match_prediction_tab";

function Tips() {

    return (
        <div className='Match-tips-container'>
            <div className='Tips-Ads-box'>
                <div>
                    <Tabs defaultActiveKey='1'>
                        <TabPane tab="Today" key='1'>
                            <PredictionTab date={getTabLabelDate(0, 'value')} />
                        </TabPane>
                        <TabPane tab="Tomorrow" key='2'>
                            <PredictionTab date={getTabLabelDate(1, 'value')} />
                        </TabPane>
                        <TabPane tab={getTabLabelDate(2, 'label')} key='3'>
                            <PredictionTab date={getTabLabelDate(2, 'value')} />
                        </TabPane>
                    </Tabs>
                </div>
                <div className='Ads-box' style={{backgroundColor: 'rgba(211,211,211,0.32)'}}>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                            crossOrigin="anonymous"></script>

                    <ins className="adsbygoogle"
                         style={{display: 'block'}}
                         data-ad-client="ca-pub-9133642311191277"
                         data-ad-slot="1789422257"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </div>
            </div>
        </div>
    );
}

const getTabLabelDate = (offset, type) => {
    let today = new Date();
    let required_date = new Date();
    required_date.setDate(today.getDate() + offset);

    if(type === 'label') {
        const options = { weekday: 'short', day: '2-digit', month: 'short' };
        const formattedDate = required_date.toLocaleDateString('en-US', options);

        const parts = formattedDate.split(' ');
        return `${parts[0]} ${parts[1]} ${parts[2]}`;
    }
    else {
        const year = required_date.getFullYear();
        const d = required_date.getDate();
        const m = required_date.getMonth() + 1;
        const month = m < 10 ? `0${m}` : `${m}`;
        const day = d < 10 ? `0${d}` : `${d}`;

        return `${year}-${month}-${day}`;
    }
}

export default Tips;