import {useEffect, useState} from "react";
import {Divider, Space} from "antd";
import "../../styles/PredictionTab.css";
import loadingIcon from '../../assets/loading_ball_icon1.png';
import refreshIcon from "../../assets/refresh_icon.svg";


function  PredictionTab(props) {
    const [loading, setLoading] = useState(false);
    const [reloads, setReloads] = useState(false);
    const [noResult, setNoResult] = useState('No prediction yet!')
    const [loadError, setLoadError] = useState(false);
    const [prediction, setPrediction] = useState([]);

    const fetchTips = () => {
        setLoadError(false);
        setLoading(true);
        let url = `https://api.i7soccer.com/varitex/api/predictions?date=${props.date}`;
        fetch(url).then(response => response.json())
            .then((data) => {
                if(data.code === 200) {
                    setPrediction(data.data);
                }
                else {
                    setNoResult(data.message);
                    setLoadError(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoadError(true);
                setLoading(false);

                console.log(`Error loading prediction: ${error}`);
            });
    }

    const fixtureFormattedDate = (date) => {
        const required = new Date(date);
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        const dateFormatter = new Intl.DateTimeFormat('en-US', options);

        const formattedDate = dateFormatter.format(required);

        return formattedDate;
    }

    useEffect(() => {
        //loads prediction
        fetchTips();
    }, [reloads]);

    const handleReload = () => {
        setReloads(!reloads);
    }

    return (
        <div className='Tips-box'>
            <div className="Tips-loading" hidden={!loading}>
                <img src={loadingIcon} className='circle-progress' width='42px' height='42px'/>
            </div>
            <div className="Tips-error" hidden={!loadError}>
                <div className='Error-box'>
                    <span>{noResult}</span>
                    <button type="button" className="btn btn-primary" onClick={handleReload}>
                        <img src={refreshIcon} alt=''/> Reload!
                    </button>
                </div>
            </div>

            <div hidden={loading || loadError} style={{backgroundColor: 'beige', width: '100%'}}>
                {
                    Array.isArray(prediction) && prediction.length > 0 &&
                    prediction.map((match_tip, index) =>
                        (<div>
                            {
                                (index === 0 || prediction[(index - 1)].league.id !== match_tip.league.id) ?
                                    <div className='League-box' style={{marginTop: (index > 0) ? '10px' : '0px'}}>
                                        <Space direction='horizontal' size='small'>
                                            <img src={match_tip.league.logo} width='16px' height='16px'/>
                                            <span>{match_tip.league.name}</span>
                                        </Space>
                                    </div> : null
                            }
                            <div className='Match-prediction-box'>
                                <div className='Tip-match-time'>
                                    <span>{fixtureFormattedDate(match_tip.fixture_date)}</span>
                                </div>
                                <div>
                                    <Space direction='vertical' size='small'>
                                        <img src={match_tip.teams.home.logo} width='16px' height='16px'/>
                                        <img src={match_tip.teams.away.logo} width='16px' height='16px'/>
                                    </Space>
                                </div>
                                <div>
                                    <Space direction='vertical' size='small'>
                                        <span>{match_tip.teams.home.name}</span>
                                        <span>{match_tip.teams.away.name}</span>
                                    </Space>
                                </div>
                                <Space direction='vertical'>
                                    <div className='Advice-tip'>{
                                        (match_tip.predictions.win_or_draw === true) ?
                                            <span>{match_tip.predictions.advice?.split(':')[1]}</span> :
                                            <span>{match_tip.predictions.winner.name}</span>
                                    }</div>
                                    <div className='Advice-tip'>
                                        <div style={{fontSize: '11px', fontStyle: 'italic', paddingRight: '10px', display: 'flex',
                                            alignItems: 'center'}}>
                                            Forms:
                                        </div>
                                        <span style={{border: `1px solid ${getBorderColor(match_tip.comparison.form.home)}`, marginRight: '4px'}}
                                              className='Prediction-form'>
                                                H:{match_tip.comparison.form.home}
                                            </span>
                                        <span style={{border: `1px solid ${getBorderColor(match_tip.comparison.form.away)}`, marginLeft: '4px'}}
                                              className='Prediction-form'>
                                                A:{match_tip.comparison.form.away}
                                            </span>
                                    </div>
                                </Space>
                            </div>
                            {
                                (index !== (prediction.length - 1) && (index + 1 <= (prediction.length - 1)) &&
                                    prediction[(index + 1)].league.id === match_tip.league.id) ?
                                    <Divider type='horizontal' className='divider-small-padding' /> : null
                            }

                            {
                                (index === 4 || index === (prediction.length - 2)) &&
                                <div className='mobile-ads'>
                                    <script async
                                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                                            crossOrigin="anonymous"></script>
                                    <ins className="adsbygoogle"
                                         style={{display: 'block'}}
                                         data-ad-client="ca-pub-9133642311191277"
                                         data-ad-slot="9413504326"
                                         data-ad-format="auto"
                                         data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({});
                                    </script>
                                </div>
                            }
                        </div>)
                    )
                }
            </div>
        </div>
    );
}

const getBorderColor = (value) => {
    let formValue = parseInt(value.replace('%',''));
    if(formValue > 45) {
        return 'rgba(6,100,122,0.68)';
    }
    else if(formValue <= 45 && formValue >= 30) {
        return '#a94b03';
    }
    else {
        return '#ff0000';
    }
}


export default PredictionTab;