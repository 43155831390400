
import Home from './pages/Home';
import NewsDetail from './pages/detail/NewsDetail';
import MatchDetail from './pages/detail/MatchDetail';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import OddsDetail from "./pages/detail/OddsDetail";
import ATRequest from "./others/account_termination";
import Privacy from "./others/privacy";
import GuvaPrivacy from "./others/guva_privacy";
import GamePrivacy from "./others/game_privacy";
import Euro2024Winner from './others/euro_2024_win';

function App() {

  return (
      <Router>
        <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/sports/stories/:id' element={<NewsDetail />}/>
            <Route path='/match/:date/:match' element={<MatchDetail />}/>
            <Route path='/odds/detail/:match' element={<OddsDetail />} />
            <Route path='/user/terminate' element={<ATRequest />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/guva/privacy' element={<GuvaPrivacy />} />
            <Route path='/game/privacy' element={<GamePrivacy />} />
            <Route path='/euro-2024/challenge/winner-terms' element={<Euro2024Winner />} />
        </Routes>
      </Router>
  );

}

export default App;
