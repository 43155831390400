import { useState, useEffect } from 'react';
import '../styles/News.css';
import loadingIcon from '../assets/loading_ball_icon1.png';
import {Space} from "antd";
import refreshIcon from "../assets/refresh_icon.svg";


function Highlights() {
    const [loading, setLoading] = useState(false);
    const [loadError, setLoadError] = useState(false);
    const [newsTop, setNewsTop] = useState([]);
    const [newsOther, setNewsOther] = useState([]);
    const [message, setMessage] = useState('No story yet!');
    const [shouldReload, setShouldReload] = useState(false);
    const [viewportSize, setViewportSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const resetLoads = (loadState, errorState) => {
        setLoading(loadState);
        setLoadError(errorState);
    };
    const handleNewsDetailNav = (id, title) => {
        const newsTitle = title.replace(" ", "+");
        let page_address = `/sports/stories/${id}?title=${newsTitle}`;
        window.open(page_address);
    };

    //fetches news highlights
    useEffect(() => {
        resetLoads(true, false);
        let url = 'https://api.i7soccer.com/varitex/api/football/browser/news';
        fetch(url).then(response => response.json())
            .then((result) => {
                const latest = result.data.latest;
                const other = result.data.others;
                if(latest.length === 0 && other.length === 0) {
                    setMessage('No Highlights yet!')
                    resetLoads(false, true);
                }
                else {
                    //bind response data to news list
                    setNewsTop(latest);
                    setNewsOther(other);
                    resetLoads(false, false);
                }
            })
            .catch((error) => {
                setMessage('Request failed. Network issue!')
                resetLoads(false, true);
                console.log(`+++++++++++++++ News Error: ${error}`);
            });

    }, [shouldReload]);

    useEffect(() => {
        const handleResize = () => {
            setViewportSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleReload = () => {
        setShouldReload(!shouldReload);
    }

    return (
        <div className='News-container'>
            <span hidden={loading || loadError}
                  style={{fontSize: '1.2em', fontFamily: 'sans-serif', paddingLeft: '10px'}}>Top Stories</span>
            <div hidden={loading || loadError} className='Top-stories-container'>
                <div className='Top-stories-box'>
                    {
                        Array.isArray(newsTop) && newsTop.length > 0 && (
                            newsTop.map((newsItem, index) => (
                                <>
                                    <div className={`card ${index === 0 ? 'Top-item' : ''}`}
                                         onClick={() => handleNewsDetailNav(newsItem._id, newsItem.title)}
                                         style={{cursor: 'pointer', overflow: 'hidden'}}>
                                        <div className={`${index === 0 ? 'row g-0' : ''}`} style={{height: '100%'}}>
                                            <div className={`zoom-image-container ${index === 0 ? 'col-md-8' : ''}`}>
                                                <img src={newsItem.imageUrl} className="card-img-top zoom-image" alt=""
                                                     style={{objectFit: 'cover', height: 'auto', maxHeight: `${index === 0 ? '280px' :
                                                             '200px'}`}}/>
                                            </div>
                                            <div className={`${index === 0 ? 'col-md-4' : ''}`}>
                                                <div className="card-body">
                                                    <Space direction='vertical' size='large'>
                                                    <span className='card-title'
                                                          style={{fontSize: '1.1em', fontWeight: 'bold'}}>
                                                        {newsItem.title}
                                                    </span>
                                                        <div className='Date-time'>
                                                        <span className='card-text'
                                                              style={{fontSize: '0.8em', fontWeight: 'normal'}}>
                                                            {formatNewsItemDate(newsItem.timestamp)}
                                                        </span>
                                                            <span className='card-text'
                                                                  style={{fontSize: '0.8em', fontWeight: 'normal'}}>
                                                            {formatNewsItemTags(newsItem.tags)}
                                                        </span>
                                                        </div>
                                                    </Space>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        index === 1 && (
                                            <div className='mobile-ads' style={{height: '95%'}}>
                                                <script async
                                                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                                                        crossOrigin="anonymous"></script>
                                                <ins className="adsbygoogle"
                                                     style={{display: 'block'}}
                                                     data-ad-client="ca-pub-9133642311191277"
                                                     data-ad-slot="9413504326"
                                                     data-ad-format="auto"
                                                     data-full-width-responsive="true"></ins>
                                                <script>
                                                    (adsbygoogle = window.adsbygoogle || []).push({});
                                                </script>
                                            </div>
                                        )
                                    }
                                </>
                            ))
                        )
                    }
                </div>
                <div style={{backgroundColor: 'rgba(208,206,206,0.22)'}} className='Top-stories-ads'>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                            crossOrigin="anonymous"></script>

                    <ins className="adsbygoogle"
                         style={{display: 'block'}}
                         data-ad-client="ca-pub-9133642311191277"
                         data-ad-slot="1789422257"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </div>
            </div>

            <div style={{
                paddingTop: '30px', fontSize: '1.2em',
                fontFamily: 'sans-serif', paddingLeft: '14px'
            }} hidden={loading || loadError}>
                <span hidden={newsOther.length === 0}>
                    More football highlights
                </span>
            </div>
            <div className="Football-highlights">
                {
                    Array.isArray(newsOther) && newsOther.length > 0 && (
                        newsOther.map((newsItem, index) => (
                            <>
                                <div className='card' onClick={() => handleNewsDetailNav(newsItem._id, newsItem.title)}
                                     style={{cursor: 'pointer', overflow: 'hidden'}}>
                                    <div style={{height: '100%'}}>
                                        <div className="zoom-image-container">
                                            <img src={newsItem.imageUrl} className="card-img-top zoom-image" alt=""
                                                 style={{objectFit: 'cover', height: 'auto', maxHeight: '200px'}}/>
                                        </div>
                                        <div>
                                            <div className="card-body">
                                                <Space direction='vertical' size='large'>
                                                    <span className='card-title'
                                                          style={{fontSize: '1.1em', fontWeight: 'bold'}}>
                                                        {newsItem.title}
                                                    </span>
                                                    <div className='Date-time'>
                                                        <span className='card-text'
                                                              style={{fontSize: '0.8em', fontWeight: 'normal'}}>
                                                            {formatNewsItemDate(newsItem.timestamp)}
                                                        </span>
                                                        <span className='card-text'
                                                              style={{fontSize: '0.8em', fontWeight: 'normal'}}>
                                                            {formatNewsItemTags(newsItem.tags)}
                                                        </span>
                                                    </div>
                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    (index === 2 || index === 7 || index === 12) && (
                                        <div className='mobile-ads' style={{height: '95%'}}>
                                            <script async
                                                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                                                    crossOrigin="anonymous"></script>
                                            <ins className="adsbygoogle"
                                                 style={{display: 'block'}}
                                                 data-ad-client="ca-pub-9133642311191277"
                                                 data-ad-slot="9413504326"
                                                 data-ad-format="auto"
                                                 data-full-width-responsive="true"></ins>
                                            <script>
                                                (adsbygoogle = window.adsbygoogle || []).push({});
                                            </script>
                                        </div>
                                    )
                                }
                            </>
                        ))
                    )
                }
            </div>

            <div hidden={!loading} className='loader'>
                <img src={loadingIcon} className='circle-progress' width='42px' height='42px'/>
            </div>
            <div style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                 hidden={!loadError}>
                <div className='Error-box'>
                    <span>{message}</span>
                    <button type="button" className="btn btn-primary" onClick={handleReload}>
                        <img src={refreshIcon} alt=''/> Reload!
                    </button>
                </div>
            </div>
        </div>
    );
}

const getItemMargins = (index, browserWidth) => {

};

const formatNewsItemDate = (dateString) => {
    if (typeof dateString !== 'undefined' && dateString !== null) {
        const dateArray = dateString.split(" ");
        if (dateArray.length === 6) {
            const day = dateArray[1];
            const month = dateArray[2].substring(0, 3);
            const year = dateArray[3].substring(2);
            const time = dateArray[4].substring(0, 5)
            // Create a new Date object
            //const date = new Date(year, month, day, hour, minute);

            return `${day} ${month}, ${year} ${time}`;
        }
        else {
            return dateString;
        }
    }
    else {
        return dateString;
    }
};

const formatNewsItemTags = (tags) => {
    if (typeof tags !== 'undefined' && tags !== null) {
        if (tags.length > 15 && tags.includes(" ")) {
            const tag = tags.split(" ")[0];

            return tag;
        }
        else {
            return tags;
        }
    }
    else {
        return "";
    }
}

export default Highlights;