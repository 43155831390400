import { useParams } from 'react-router-dom';
import '../../styles/NewsDetail.css';
import { useEffect, useState } from 'react';
import loadingIcon from "../../assets/loading_ball_icon1.png";

function NewsDetail() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("Error fetching News Item!");
    const [loadError, setLoadError] = useState(false);
    const [newsItem, setNewsItem] = useState(null);
    const resetLoads = (loadState, errorState) => {
        setLoading(loadState);
        setLoadError(errorState);
    };

    useEffect(() => {
        let url = `https://api.i7soccer.com/varitex/api/football/news/${id}`;
        if(typeof id !== 'undefined') {
            fetch(url).then(response => response.json())
                .then((result) => {
                    if (result.code === 200) {
                        //bind response data to news list
                        setNewsItem(result.data);
                        resetLoads(false, false);

                        console.log(`News Highlights: ${JSON.stringify(result.data.title)}`);
                    }
                    else {
                        setMessage(result.message);
                        resetLoads(false, true);
                    }
                })
                .catch((error) => {
                    setMessage("Network issue. try again!");
                    resetLoads(false, true);

                    console.log(`+++++++++++++++ News Error: ${error}`);
                });
        }
    }, []);

    return (
        <div className='News-detail'>

            <div hidden={loading || loadError} className="News-detail-container">
                {
                    newsItem !== null &&
                    <div className='News-box'>
                        {
                            newsItem.subTitle.length > 50 ?
                                <h3>{newsItem.subTitle}</h3> :
                                <h3>{newsItem.title}</h3>
                        }

                        <div className='mobile-ads'>
                            <script async
                                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                                    crossOrigin="anonymous"></script>
                            <ins className="adsbygoogle"
                                 style={{display: 'block'}}
                                 data-ad-client="ca-pub-9133642311191277"
                                 data-ad-slot="9413504326"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>
                            <script>
                                (adsbygoogle = window.adsbygoogle || []).push({});
                            </script>
                        </div>

                        <p></p>
                        <img src={newsItem.imageUrl} srcSet={newsItem.imageUrls}
                             style={{ width: '100%', objectFit: 'contain', margin: 'auto 0' }}
                        />
                        <p></p>
                        <p>{newsItem.snippets}</p>
                        <div dangerouslySetInnerHTML={{ __html: newsItem.highlights }} />

                        <div className='mobile-ads'>
                            <script async
                                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                                    crossOrigin="anonymous"></script>
                            <ins className="adsbygoogle"
                                 style={{display: 'block'}}
                                 data-ad-client="ca-pub-9133642311191277"
                                 data-ad-slot="9413504326"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>
                            <script>
                                (adsbygoogle = window.adsbygoogle || []).push({});
                            </script>
                        </div>

                        <div dangerouslySetInnerHTML={{ __html: newsItem.highlightsTwo }} />
                    </div>
                }
                <div className='Ads-box'>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9133642311191277"
                            crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                         style={{display: 'block'}}
                         data-ad-client="ca-pub-9133642311191277"
                         data-ad-slot="1789422257"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </div>
            </div>

            <div hidden={!loading} className='loader'>
                <img src={loadingIcon} className='circle-progress' width='42px' height='42px'/>
            </div>
            <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                 hidden={!loadError && !loading}>
                <div className="alert alert-error" role="alert">
                    <span>{message}</span>
                </div>
            </div>
        </div>
    );
}

export default NewsDetail;
