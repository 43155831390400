import {useEffect, useState} from "react";
import {Alert} from "antd";
import '../styles/userTermination.css'

function ATRequest() {
    const [formData, setFormData] = useState({username: '', email: ''});
    const [submitRequest, setSubmitRequest] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [alertMessage, setAlertMessage] = useState('request sent.');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        setShowAlert(false);
        setSubmitting(true);
        setSubmitRequest(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({...formData, [name]: value});
    };

    useEffect(() => {
        if(submitRequest) {
            const url = `https://www.i7soccer.com/varitex/varitex/api/user/terminate?email=${formData.email}&username=${formData.username}`;
            fetch(url).then(response => response.json())
                .then((data) => {
                    console.log(data);
                    // Reset form after submission
                    setFormData({username: '', email: ''});
                    setAlertType('success')
                    setAlertMessage('request sent.');
                    setSubmitting(false);
                    setShowAlert(true);
                })
                .catch((error) => {
                    console.log(`load error: ${error}`);
                    setAlertType('error');
                    setAlertMessage('request failed.')
                    setSubmitting(false);
                    setShowAlert(true);
                })
                .finally(() => {
                    setSubmitting(false);
                    setShowAlert(true);
                });
        }
    }, [submitRequest]);

    return (
        <div className='main-box'>
            <div style={{display: 'flex', justifyContent: 'flex-end'}} hidden={!showAlert}>
                <Alert
                    message={alertMessage}
                    type={alertType}
                    showIcon
                />
            </div>
            <h1>Account deletion request</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button style={{backgroundColor: submitting ? 'lightslategrey' : '#04AA6D'}}
                        className='button-box' type="submit"
                        disabled={submitting}
                >{
                    submitting ? 'Requesting' : 'Submit'
                }</button>
            </form>
        </div>);
}

export default ATRequest;

