import '../../styles/OddsDetail.css';
import '../../tabs/odds/odd_detail_tab'
import {Space, Tabs} from "antd";
import {useState} from "react";
import OddDetailTab from "../../tabs/odds/odd_detail_tab";

function OddsDetail() {
    const odd_data = JSON.parse(localStorage.getItem('matchOdd'));
    const [activeKey, setActiveKey] = useState('1');
    const tabLabels = [];

    const handleTabChange = (key) => {
        setActiveKey(key);
    }

    const getFormattedDate = () => {
        const date = new Date(odd_data.fixture.date);
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).
            format(date);
        return formattedDate;
    }
    const setData = () => {
        return odd_data.bookmakers.filter((data) => data.name === tabLabels[activeKey - 1])[0];
    }

    return (
        <div className='Odd-details'>
            <div className='Odd-details-box'>
                <div>
                    <div className='Header-detail'>
                        <Space direction='vertical' size='middle' className='Team-logo-label'>
                            <img src={odd_data.teams.home.logo} width='50px' height='50px' />
                            <span className='Team'>{odd_data.teams.home.name}</span>
                        </Space>

                        <Space direction='vertical' size={1} className='Status-score'>
                            <span>{getFormattedDate().split(',')[0]}</span>
                            <span>{getFormattedDate().split(',')[1]}</span>
                        </Space>

                        <Space direction='vertical' size='middle' className='Team-logo-label'>
                            <img src={odd_data.teams.away.logo} width='50px' height='50px' />
                            <span className='Team'>{odd_data.teams.away.name}</span>
                        </Space>
                    </div>
                    <>
                        <Tabs
                            defaultActiveKey={activeKey}
                            items={odd_data.bookmakers.filter(data =>
                                data.name === '1xBet' || data.name === 'Bwin' ||
                                data.name === '10Bet' || data.name === 'Bet365' ||
                                data.name === 'Marathonbet' || data.name === 'Betfair' ||
                                data.name === '188Bet' || data.name === 'Unibet'
                            ).map((data, index) => {
                                tabLabels.push(data.name);

                                return {
                                    key: `${index + 1}`,
                                    label: `${data.name}`
                                }
                            })}
                            onChange={handleTabChange}
                        />
                        <div>
                            <OddDetailTab bookmaker={setData()} home={odd_data.teams.home.name} away={odd_data.teams.away.name} />
                        </div>
                    </>
                </div>
                <div style={{backgroundColor: 'lightgrey', height: '100vh'}}>
                    Ads space
                </div>
            </div>
        </div>
    );
}

export default OddsDetail;