import '../../styles/OddsDetail.css';

function OddDetailTab(props) {

    return (
        <div>
            {
                Array.isArray(props.bookmaker.bets) && props.bookmaker.bets.sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                }).map((bet) =>
                    <div style={{border: '1px solid lightgrey', backgroundColor: 'beige'}}>{
                        (bet.values.length === 3 || bet.values.length === 2) ? <div>
                            <div className='Bet-header'>
                                {bet.name}
                            </div>
                            <div>{
                                (bet.values.length === 3) ? <div>
                                    {
                                        oddItemThreeCol(bet.values)
                                    }
                                    </div> :
                                    (bet.values.length === 2) ? <div>{
                                        oddItemTwoCol(bet.values)
                                    }</div> :
                                        <div></div>
                            }</div>
                        </div> :
                            (bet.name === "Goals Over/Under" || bet.name === 'Goals Over/Under - Second Half'
                                || bet.name === 'Goals Over/Under First Half' || bet.name === 'Total - Home'
                                || bet.name === 'Corners Over Under' || bet.name === 'Home Team Total Goals(1st Half)'
                                || bet.name === 'Away Team Total Goals(1st Half)' || bet.name === 'Home Team Total Goals(2nd Half)'
                                || bet.name === 'Away Team Total Goals(2nd Half)' || bet.name === 'Home Corners Over/Under'
                                || bet.name === 'Away Corners Over/Under' || bet.name === 'Total - Away'
                            ) ? <div>
                                    <div className='Bet-header'>
                                    {bet.name}
                                </div>
                                    {
                                        goalOverUnder(bet.values)
                                    }
                                </div> :
                                (bet.name === 'Exact Score' || bet.name === 'Correct Score - First Half') ? <div>
                                    <div className='Bet-header'>
                                        {bet.name}
                                    </div>
                                    {
                                        goalCorrectScore(bet.values)
                                    }
                                </div> : null
                    }</div>)
            }
        </div>
    );
}

const oddItemThreeCol = (values) => {
    return (
        <div className='Odd-Item-3-col'>
            <div className='Odd-Item-data'>
                <div>{values[0].value}</div>
                <div className='Odd-item-value'>{values[0].odd}</div>
            </div>
            <div className='Odd-Item-data' style={{borderRight: '1px solid lightgrey',
                    borderLeft: '1px solid lightgrey'}}>
                <div>{values[1].value}</div>
                <div className='Odd-item-value'>{values[1].odd}</div>
            </div>
            <div className='Odd-Item-data'>
                <div>{values[2].value}</div>
                <div className='Odd-item-value'>{values[2].odd}</div>
            </div>
        </div>
    )
}

const oddItemTwoCol = (values) => {
    return (
        <div className='Odd-Item-2-col'>
            <div className='Odd-Item-data'>
                <div>{values[0].value}</div>
                <div className='Odd-item-value'>{values[0].odd}</div>
            </div>
            <div className='Odd-Item-data' style={{borderLeft: '1px solid lightgrey'}}>
                <div>{values[1].value}</div>
                <div className='Odd-item-value'>{values[1].odd}</div>
            </div>
        </div>
    )
}

const goalOverUnder = (values) => {
    let odds_over = getOverOdds(values);
    let odds_under = getUnderOdds(values);
    let odds_length = (odds_over.length > odds_under) ? odds_over.length : odds_under.length;
    return (
        <div>
            {
                [...Array(odds_length)].map((_, index) => (<div>
                    <div className='Odd-Item-3-col' style={{borderBottom: '1px solid lightgrey'}}>
                        <div className='Odd-Item-data'>
                            <div>{
                                odds_over[index]?.value.replace('Over','').trim() ??
                                    odds_over[index]?.value.replace('Under','').trim() ?? '--'
                            }</div>
                            <div style={{width: '10px', height: '10px'}}></div>
                        </div>
                        <div className='Odd-Item-data' style={{borderRight: '1px solid lightgrey',
                            borderLeft: '1px solid lightgrey'}}>
                            <div>Over</div>
                            <div className='Odd-item-value'>{odds_over[index]?.odd ?? '--'}</div>
                        </div>
                        <div className='Odd-Item-data'>
                            <div>Under</div>
                            <div className='Odd-item-value'>{odds_under[index]?.odd ?? '--'}</div>
                        </div>
                    </div>
                </div>))
            }
        </div>
    )
}

const goalCorrectScore = (values) => {
    let home_correct = getHomeCorrect(values);
    let away_correct = getAwayCorrect(values);
    let draw_correct = getDrawCorrect(values);
    let correct_length = (home_correct.length > away_correct) ? home_correct.length :
        (away_correct > draw_correct) ? away_correct.length : draw_correct;
    return (
        <div>
            {
                [...Array(correct_length)].map((_, index) => (<div>
                    <div className='Odd-Item-3-col' style={{borderBottom: '1px solid lightgrey'}}>
                        <div className='Odd-Item-data'>
                            <div>
                                {home_correct[index]?.value ?? '--'}
                            </div>
                            <div className='Odd-item-value'>
                                {home_correct[index]?.odd ?? '--'}
                            </div>
                        </div>
                        <div className='Odd-Item-data' style={{borderRight: '1px solid lightgrey',
                            borderLeft: '1px solid lightgrey'}}>
                            <div>
                                {draw_correct[index]?.value ?? '--'}
                            </div>
                            <div className='Odd-item-value'>
                                {draw_correct[index]?.odd ?? '--'}
                            </div>
                        </div>
                        <div className='Odd-Item-data'>
                            <div>
                                {away_correct[index]?.value ?? '--'}
                            </div>
                            <div className='Odd-item-value'>
                                {away_correct[index]?.odd ?? '--'}
                            </div>
                        </div>
                    </div>
                </div>))
            }
        </div>
    )
}

const getUnderOdds = (values) => {
    let under_odds = [];
    values.forEach((odd_value) => {
        if(odd_value.value.includes('Under') === true) {
            under_odds.push(odd_value)
        }
    });
    //sort the
    under_odds.sort((a, b) => parseFloat(b.odd) - parseFloat(a.odd));
    return under_odds;
}

const getOverOdds = (values) => {
    let over_odds = [];
    values.forEach((odd_value) => {
        if(odd_value.value.includes('Over') === true) {
            over_odds.push(odd_value)
        }
    });
    //sort the
    over_odds.sort((a, b) => parseFloat(a.odd) - parseFloat(b.odd));
    return over_odds;
}

const getHomeCorrect = (values) => {
    let home_scores = [];
    values.forEach((v) => {
        let value = v?.value ?? '0:0';
        let array_score = value.split(':')
        let home_score = parseInt(array_score[0]);
        let away_score = parseInt(array_score[1]);
        if(home_score > away_score) {
            home_scores.push(v);
        }
    });

    return home_scores;
}

const getAwayCorrect = (values) => {
    let away_scores = [];
    values.forEach((v) => {
        let value = v?.value ?? '0:0';
        let array_score = value.split(':')
        let home_score = parseInt(array_score[0]);
        let away_score = parseInt(array_score[1]);
        if(home_score < away_score) {
            away_scores.push(v);
        }
    });

    return away_scores;
}

const getDrawCorrect = (values) => {
    let draw_scores = [];
    values.forEach((v) => {
        let value = v?.value ?? '0:0';
        let array_score = value.split(':')
        let home_score = parseInt(array_score[0]);
        let away_score = parseInt(array_score[1]);
        if(home_score == away_score) {
            draw_scores.push(v);
        }
    });

    return draw_scores;
}

export default OddDetailTab;