import '../styles/euro_2024.css';
import ChallengeGuild from '../assets/euro_2024_challenge.jpg';

function Euro2024Winner() {
    return (<div className="euro_box">
        <h1>Terms & Conditions</h1>
        <h3>To qualify for the prize of ₦5,000:</h3>
        <ol>
            <li><a href="https://play.google.com/store/apps/details?id=com.casontek.soccer.i6football&hl=en">Download i7Soccer</a> mobile app from the Play Store.</li>
            <li>Log in with your Google account.</li>
            <li>Open the challenge and select one Team/Country that you believe will win the 2024 Euro Cup.</li>
            <li>Screenshot your selection and share it on our social media handle.</li>
            <li>Be among the first 10 participants to pick the winning Team/Nation.</li>
            <li>Ensure your prediction is made between 19th June and 10th July.</li>
        </ol>
        <div>
            <img src={ChallengeGuild} width={420} alt='Sample guild'/>
        </div>
    </div>);
}

export default Euro2024Winner;