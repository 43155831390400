import '../styles/MatchOddItem.css';
import {Space} from "antd";

function  MatchOddItem(props) {
    return (<Space direction='horizontal' size='small'>
        <div className='Marker-name'>{props.bookmarker.name}</div>
        <div className='Marker-value'>{props.bookmarker.bets[0].values[0].odd}</div>
        <div className='Marker-value'>{props.bookmarker.bets[0].values[1].odd}</div>
        <div className='Marker-value'>{props.bookmarker.bets[0].values[2].odd}</div>
    </Space>)
}


export default MatchOddItem;