import newsIconActive from '../assets/newspaper_active.png';
import newsIcon from '../assets/newspaper.png';
import matchIconActive from '../assets/goal_active.png';
import matchIcon from '../assets/goal.png';
import tipsIconActive from '../assets/football_boot_active.png';
import tipsIcon from '../assets/football_boot.png';
import oddIconActive from '../assets/net_active.png';
import oddIcon from '../assets/net.png';
import refreshIcon from '../assets/arrow_clockwise.svg';
import Highlights from '../pages/Highlights';
import MatchOdds from '../pages/MatchOdds';
import Matches from '../pages/Matches';
import Tips from '../pages/Tips';
import '../styles/Home.css';
import { useEffect, useState } from 'react';

function Home() {
    const [current, setCurrent] = useState("/");
    const [apiKey, setApiKey] = useState("");
    const [shouldReloads, setShouldReloads] = useState(false);
    const [initializationError, setInitializationError] = useState(false);
    const [initializing, setInitializing] = useState(true);

    const refreshFootballKeyCall = () => {
        setInitializationError(false);
        setShouldReloads(!shouldReloads);
        setInitializing(true);
    }

    useEffect(() => {
        //fetch football api key
        const url = 'https://api.i7soccer.com/varitex/api/football/key';
        fetch(url).then(response => response.json())
            .then((result) => {
                //sets api key
                setApiKey(result.data.key);
                setInitializing(false);
            })
            .catch((error) => {
                setInitializing(false);
                setInitializationError(true);
            });
    }, [shouldReloads]);

    return (
        <div className="App">
            <header className="Header" hidden={initializing || initializationError}>
                <div className='Header-item' onClick={() => setCurrent("/")}
                     style={{ color: (current === "/") ? 'darkorange' : 'white' }}>
                    <img alt="Matches" width="18" height="18"
                         src={(current === "/") ? matchIconActive : matchIcon} />
                    <span>&nbsp;&nbsp;Matches</span>
                </div>
                <div className='Header-item' onClick={() => setCurrent("/highlights")}
                     style={{ color: (current === "/highlights") ? 'darkorange' : 'white' }}>
                    <img alt="Football News" width="18" height="18"
                         src={(current === "/highlights") ? newsIconActive : newsIcon} />
                    <span>&nbsp;&nbsp;News</span>
                </div>
                <div className='Header-item' onClick={() => setCurrent("/predictions")}
                     style={{ color: (current === "/predictions") ? 'darkorange' : 'white' }}>
                    <img alt="Tips" width="20" height="20"
                         src={(current === "/predictions") ? tipsIconActive : tipsIcon} />
                    <span>&nbsp;&nbsp;Tips</span>
                </div>
                <div className='Header-item' onClick={() => setCurrent("/odds")}
                     style={{ color: (current === "/odds") ? 'darkorange' : 'white' }}>
                    <img alt="Match Odds" width="20" height="20"
                         src={(current === "/odds") ? oddIconActive : oddIcon} />
                    <span>&nbsp;&nbsp;Odds</span>
                </div>
            </header>
            <div hidden={initializing || initializationError}>
                {
                    apiKey.length > 0 ? (
                        current === "/" ? <Matches apikey={apiKey} source="apifootball" /> :
                            current === "/highlights" ? <Highlights /> :
                                current === "/predictions" ? <Tips /> :
                                    current === "/odds" ? <MatchOdds apiKey={apiKey} /> : null
                    ) : null
                }
            </div>
            <div className="InitLoad" hidden={!initializing && !initializationError}>
                <button className="btn btn-primary" type="button" disabled
                        hidden={!initializing}>
                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span role="status">&nbsp;Loading...</span>
                </button>
                <button className="btn btn-primary" type="button" hidden={!initializationError}
                        onClick={refreshFootballKeyCall}>
                    <img src={refreshIcon} style={{ color: 'white' }} alt='' /> Click Refresh!
                </button>
            </div>
        </div>
    );

}

export default Home;
